module.exports = [{
      plugin: require('../node_modules/@dfb/gatsby-dfb-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DFB-Akademie","short_name":"DFB-Akademie","start_url":"/","icon":"src/images/dfb-akademie-logo-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9de63780c905ad786996837a5504e6a1"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
